const dialogs = [

  ///////////////////////////////////////////////////////////
  // Basic

  [{ lines: [ "A fairy appears.",
              "“I wouldn't click there if I were you...”" ],
     opts:  [ "I'll be more careful.",
              { text: "I don't need your help, let me suffer!", explode: true },
              { text: "Thanks! Have some money.", donate: true }]}],

  ///////////////////////////////////////////////////////////
  // Gullveig

  [{ lines: [ "A strange being appears." ],
     auto:  { delay: 1500 }},
   { lines: [ { text: "Gullveig the Fae", bold: true },
              "“I don't think you want to click there, mate.",
              "\xa0\xa0\xa0It looks scary.”" ],
     opts:  [ "What do you mean?",
              { text: "Thanks, I'll be more careful.", target: "END" }]},
   { lines: [ { text: "Gullveig the Fae", bold: true },
              "“I'm going to be more explicit:\xa0\xa0\xa0\xa0\xa0",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0BOOM" +
                  "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0KAPOW",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                  "\xa0\xa0\xa0\xa0CRASH”" ],
     opts:  [ "Ok, ok, I got it." ]},
   { label: "END",
     lines: [ { text: "Gullveig the Fae", bold: true },
              "“Good. Glad I could help.”" ],
     opts:  [ { text: "Thanks, Dude!", end: true },
              { text: "Here, I have a little something for you.",
                donate: true }]},
   { lines: [ { text: "Gullveig the Fae", bold: true },
              "“Wow. That's better than pixie dust!”" ],
     opts:  [ "You're welcome!" ]}],


  ///////////////////////////////////////////////////////////
  // Aerwyna

  [{ lines: [ "A voice in your head tells you to stop." ],
     opts:  [ "Close your eyes and listen carefully.",
              { text: "Turn around quickly.", target: "OPEN" }]},
   { lines: [ { text: "Voice", bold: true },
              "“Are you mad?",
              "\xa0\xa0\xa0You can't sweep with your eyes closed!”"],
     opts:  [ "Keep your eyes closed, this will pass.",
              { text: "Open your eyes.", target: "OPEN" }]},
   { label: "WAIT",
     lines: [ "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0.\xa0\xa0\xa0.\xa0\xa0\xa0.",
              "\xa0" ],
     auto:  { delay: 2000 }},
   { lines: [ "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0.\xa0\xa0\xa0.\xa0\xa0\xa0.",
              "\xa0" ],
     auto:  { delay: 2000 }},
   { lines: [ { text: "Voice", bold: true },
              "“I'm not going away.”"],
     opts:  [ { text: "Shut your eyes harder.", target: "WAIT" },
              "Open your eyes." ]},
   { label: "OPEN",
     lines: [ { text: "Aerwyna the Fairy", bold: true },
              "“Good day sweet sweeper.",
              "\xa0\xa0\xa0Too good to die, I'd say.”" ],
     opts:  [ "Whoa, what are you?",
              { text: "I can't die! I have logic!", target: "LOGIC" },
              { text: "I'm going to pretend this never happened.",
                target: "END" }]},
   { lines: [ { text: "Aerwyna the Fairy", bold: true },
              "“I'm Aerwyna. I'm a fairy.",
              "\xa0\xa0\xa0I work hard to keep sweepers safe.”" ],
     opts:  [ "Magic isn't real! There's only logic!",
              { text: "You deserve some money for all that work.",
                donate: true, target: "DONATED" },
              { text: "I can't believe a fairy saved me!", target: "END" }]},
   { label: "LOGIC",
     lines: [ "Aerwyna is visibly upset.",
              "She turns around and leave.",
              "You got the strange feeling you're on your own now." ],
     auto:  { delay: 4000, end: true }},
   { label: "END",
     lines: [ { text: "Aerwyna the Fairy", bold: true },
              "“You better start believing, sweet sweeper.",
              "\xa0\xa0\xa0Without us you wouldn't be here today.”" ],
     opts:  [ { text: "Sure, thanks. Can I keep on sweeping now?",
                target: "LOGIC" },
              { text: "Thanks, here's some money for you dear fairy.",
                donate: true, target: "DONATED" }]},
   { label: "DONATED",
     lines: [ { text: "Aerwyna the Fairy", bold: true },
              "“This is so nice of you, sweet sweeper.",
              "\xa0\xa0\xa0I'll make sure your generosity is known",
              "\xa0\xa0\xa0through all the fairy planes!”" ],
     opts:  [ "You're welcome, Aerwyna!" ]}],




  ///////////////////////////////////////////////////////////
  // Gurkie

  [{ lines: [ "An unseen force prevents",
              "your finger from clicking." ],
     opts:  [ "Click harder.",
              { text: "Slooowly retract your finger.", target: "APPEAR" }]},
   { lines: [ { text: "A voice behind you", bold: true },
              "“I woudn't push my luck",
              "\xa0\xa0\xa0if I were you.”" ],
     opts:  [ "Stop clicking." ]},
   { label: "APPEAR",
     lines: [ "A small winged creature flies from behind you." ],
     auto:  { delay: 2000 }},
   { lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“Hi Minemaster. Name's Gurkie.",
              "\xa0\xa0\xa0You lucky I was around.”" ],
     opts:  [ "Gurkie? What a ridiculous name!",
              { text: "You seem to know quite a bit about luck.",
                target: "LUCK" }]},
   { lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“Gurkie? Ridiculous?",
              "\xa0\xa0\xa0It's one of the finest family of the Luck Faes!”" ],
     opts:  [ "I still think it sounds like a pickle.",
              { text: "The Luck Faes?", target: "LUCK" }]},
   { lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“You little twat, you! I should teach you a lesson",
              "\xa0\xa0\xa0and click on this mine myself!”" ],
     opts:  [ "Go away, pickle, I have work to do.",
              { text: "Give some money to the Fae to appease him.",
                donate: true, target: "DONATED_BAD" }]},
   { lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“May all your guesses be wrong!”",
              "\xa0",
              "Gurkie flies away, cursing." ],
     auto:  { delay: 4000, end: true }},
   { label: "DONATED_BAD",
     lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“Yeah, ok. I'll forgive you this time.”" ],
     opts:  [ { text: "Thanks great Gurkie.", end: true },
              { text: ".\xa0\xa0\xa0.\xa0\xa0\xa0.", end: true }]},
   { label: "LUCK",
     lines: [ { text: "Gurkie the Luck Fae", bold: true },
                "“Yes. See, I'm the great inventor of the famous",
                "\xa0\xa0\xa0Reduce Bad Luck™.”" ],
     opts:  [ { text: "Wow. Thanks for saving my ass, Gurkie.", end: true },
              { text: "Really? This deserves a small donation!",
                donate: true }]},
   { lines: [ { text: "Gurkie the Luck Fae", bold: true },
              "“Very generous, Minemaster. Thank you.”" ],
     opts:  [ "You're welcome, now bring me luck!" ]}],



  ///////////////////////////////////////////////////////////
  // Swalla-Swalla

  [{ lines: [ "You suddenly hear a faint chant, like an incantation",
              "“Swalla-swalla! Swalla-swalla! Swalla-swalla!”", ],
     opts:  [ "Look for the source of that noise." ]},
   { lines: [ "Mist gathers in front of your eyes and",
              "shapes itself into a plumpy winged beast." ],
     auto:  { delay: 4000 }},
   { lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“Greatings mortal.",
              "\xa0\xa0\xa0I am Swalla-Swalla (She/Her).”" ],
     opts:  [ "Swalla-Swalla?",
              { text: "Why wont you let me click?", target: "CLICK" }]},
   { lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“You shall address me as her Highness Swalla-Swalla.",
              "\xa0\xa0\xa0I am queen of the faeries.”" ],
     opts:  [ "A queen? You must me rich!",
              { text: "This is nice and all, but can I just want to click...",
                target: "CLICK" }]},
   { lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“Well... We need a lot of funds to clean the mess",
              "\xa0\xa0\xa0you sweepers cause as you click randomly.”" ],
     opts:  [ { text: "I understand, let me help you with this...",
                donate: true, target: "DONATED" },
              "There wont be any mess if you don't let me click." ]},
   { label: "CLICK",
     lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“Sure, you can click, but do you want to click...",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                  "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0...here?",
              "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                  "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0...or there?" ],
     opts:  [ "Here!",
              "There!" ]},
   { lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“Wrong choice! Ah ah ah ah!",
              "\xa0\xa0\xa0Swalla-swalla! Swalla-swalla! Swalla-swalla!“" ],
     opts:  [ { text: "Ignore the queen and go back to work.", end: true },
              { text: "Throw her some money so she shut up.", donate: true }]},
   { label: "DONATED",
     lines: [ { text: "Her Highness Swalla-Swalla", bold: true },
              "“Ooooh! Shiny!",
              "\xa0\xa0\xa0Swalla-swalla! Swalla-swalla! Swalla-swalla!“" ],
     opts:  [ "She's pretty far gone..." ]}],

];

export default dialogs;